.add-article-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form-container {
  z-index: 1;
  width: 80%;
  max-width: 600px;
  /* height: 80%; */
  padding: 20px;
  background-color: rgba(240, 240, 240, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-weight: bold;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submitbutton {
  background-color: #7D4EDA; 
  color: #fff;
  padding: 10px 20px;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitbutton:hover {
  background-color: #0056b3;
}

.button-container {
  display: flex;
  justify-content: center;
}

.textarea-container {
  max-width: 100%;
  max-height: 400px; /* Adjust max-height as per your design needs */
  overflow: auto; /* Enable scrolling if content exceeds max-height */
  padding: 10px;
}

textarea {
  width: 100%;
  height: 100%;
  /* height: 225px; */
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allow vertical resizing only */
  max-width: 100%;
  max-height: 100%; /* Ensure textarea doesn't exceed its container */
}
