/* General styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

/* Profile Container */
.profile-container {
  max-width: 900px;
  padding: 20px;
  text-align: center;
}

/* Profile Header */
.profile-header {
  margin-bottom: 20px;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 10px;
}

.username {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.userlastname {
  font-size: 22px;
  margin: 10px 0;
}

.handle {
  font-size: 16px;
  color: #666;
}

/* Profile Stats */
.profile-stats {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.stat {
  background: #f5f5f5;
  padding: 10px 20px;
  border-radius: 10px;
  text-align: center;
}

.stat-value {
  display: block;
  font-size: 20px;
  font-weight: bold;
}

.stat-label {
  display: block;
  font-size: 14px;
  color: #999;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .profile-container {
    padding: 10px;
  }

  .profile-image {
    width: 100px;
    height: 100px;
  }

  .username {
    font-size: 20px;
  }

  .profile-stats {
    flex-direction: column;
  }

  .stat {
    margin-bottom: 10px;
  }
}