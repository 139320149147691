body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
}

.Createcount {
  max-width: 600px;
  margin: 50px auto;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.Title {
  text-align: center;
  margin-bottom: 20px;
}

.Title img {
  max-width: 100px;
}

.forme_main {
  display: flex;
  flex-wrap: wrap;
}

.headinge {
  font-size: 30px;
  margin-bottom: 20px;
  text-align: center;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
}

.inputContainere {
  flex: 0 0 calc(50% - 10px);
  margin-bottom: 15px;
  margin-right: 10px;
}

.inputField {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px !important;
  outline: none;
  transition: border-color 0.3s;
  margin-bottom: 10px;
  margin-top: 0px;
}

.labelStyle {
  color: gray;
  font-size: 12px;
  margin-bottom: 0px;
}

.inputField:focus {
  border-color: #0000ff; /* Blue color for focus */
}

.passwordRequirements {
  font-size: 12px;
  color: #666;
  margin-top: -10px;
  margin-bottom: 10px;
}

.passwordRequirements .blue-text {
  color: #0000ff;
}

.error {
  color: red;
  font-size: 12px;
}

.success-message {
  color: green;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.inputLabel {
  margin-bottom: 5px;
  font-weight: bold;
}

.buttonContainer {
  display: flex;
  width: 600px;
  justify-content: center; /* Center horizontally */
}

#button {
  background-color: #0000ff; /* Blue color */
  color: #fff;
  padding: 10px 20px;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#button:hover {
  background-color: #0000cc;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .inputContainere {
    flex: 1 0 100%;
    margin-bottom: 15px;
    margin-right: 0;
  }
}
