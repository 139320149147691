/* Common Styles (for all screen sizes) */
.form_maine {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 4px 10px rgba(199, 114, 255, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  position: absolute;
  right: 15%;
  top: 20%;
  transform: scale(1.4);
}

.login,
.heading {
  font-size: 1.5em;
  color: rgb(0, 0, 0);
  font-weight: 500;
  margin: 15px 0 30px 0;
  text-align: center;
}

.heading {
  font-size: 2.5em;
  color: #2e2e2e;
  font-weight: 700;
  padding: 10px 80px;
}

.inputContainer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inputFields {
  width: 225px;
  height: 35px;
  background-color: transparent;
  /* border: none;
  border-bottom: 2px solid rgb(173, 173, 173); */
  /* border-radius: 30px; */
  margin: 5px 0;
  color: black;
  font-size: 0.8em;
  /* font-weight: 500; */
  /* box-sizing: border-box; */
}

.inputFields:focus {
  outline: none;
  /* border-bottom: 2px solid rgb(199, 114, 255); */
}

#buttonlogin {
  width: 75px;
  border: 2px solid #2e2c31;
  background-color: #2d2a31;
  height: 35px;
  color: white;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}

#button::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.253);
  height: 100%;
  width: 150px;
  top: 0;
  left: -200px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 100px;
  filter: blur(10px);
  transition-duration: 0.5s;
}

#button:hover::after {
  transform: translateX(600px);
  transition-duration: 0.5s;
}

.signupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  font-size: 0.8em;
}

.signupContainer p {
  font-size: 0.9em;
  font-weight: 500;
  color: black;
}

.signupContainer a {
  font-size: 0.9em;
  font-weight: 500;
  background-color: #8000ff;
  color: white;
  text-decoration: none;
  padding: 8px 15px;
  margin-top: 10px;
}

.recover {
  border: 2px solid #6f6ff0;
  padding: 10px;
  background-color: #6f6ff0;
  color: white;
}

.forgot {
  font-size: 0.8em;
}

.hr {
  width: 100%;
  border: 1px solid #000000;
  margin: 20px 0;
}

.signupContainer a:hover {
  color: white;
}

@media only screen and (max-width: 768px) {
  .form_maine {
    position: relative; /* Make the form position relative on smaller screens */
    right: auto; /* Reset right position */
    top: auto; /* Reset top position */
    transform: none; /* Reset transform */
    width: 90%; /* Full width for mobile screens */
    margin: 20px auto; /* Center the form horizontally */
    padding: 20px; /* Adjust padding */
  }

  .inputFields {
    width: 100%; /* Full width for input fields */
    font-size: 0.9em;
  }

  .heading {
    font-size: 2em;
    padding: 10px 60px;
  }

  .login {
    font-size: 2em;
    padding: 10px 60px;
  }

  #buttonlogin {
    font-size: 1.7em;
    width: 100%;
    min-height: 40px; /* Adjust button height */
  }
}

@media only screen and (min-width: 360px) and (max-width: 1000px) {
  .form_maine {
    position: relative; /* Make the form position relative on smaller screens */
    right: auto; /* Reset right position */
    top: auto; /* Reset top position */
    transform: none; /* Reset transform */
    width: 90%; /* Full width for mobile screens */
    margin: 20px auto; /* Center the form horizontally */
  }

  .signupContainer a {
    max-width: 90%;
    font-size: 0.9em;
    font-weight: 500;
    padding: 2px 5px;
  }

  .signupContainer {
    gap: 10px;
  }

  .inputFields {
    font-size: 0.7em;
  }

  .heading {
    font-size: 1.8em;
    padding: 8px 40px;
  }

  .login {
    font-size: 1.8em;
    padding: 1px 35px;
  }

  #buttonlogin {
    font-size: 0.7em;
  }
}

/* iPhone SE */
@media only screen and (max-device-width: 375px) and (max-device-height: 740px) {
  .form_maine {
    position: relative; /* Make the form position relative on smaller screens */
    right: auto; /* Reset right position */
    top: auto; /* Reset top position */
    transform: none; /* Reset transform */
    width: 90%; /* Full width for mobile screens */
    margin: 20px auto; /* Center the form horizontally */
  }
}

/* iPhone 12 Pro */
@media only screen and (max-device-width: 390px) and (max-device-height: 884px) {
  .form_maine {
    position: relative; /* Make the form position relative on smaller screens */
    right: auto; /* Reset right position */
    top: auto; /* Reset top position */
    transform: none; /* Reset transform */
    width: 90%; /* Full width for mobile screens */
    margin: 20px auto; /* Center the form horizontally */
  }
}

/* iPad Mini */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .form_maine {
    position: relative; /* Make the form position relative on smaller screens */
    right: auto; /* Reset right position */
    top: auto; /* Reset top position */
    transform: none; /* Reset transform */
    width: 70%; /* Full width for mobile screens */
    margin: 20px auto; /* Center the form horizontally */
  }

  .inputFields {
    font-size: 1em;
  }

  .heading,
  .login {
    font-size: 2em;
    padding: 12px 70px;
  }
}

/* iPad Air */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) {
  .form_maine {
    position: relative; /* Make the form position relative on smaller screens */
    right: auto; /* Reset right position */
    top: auto; /* Reset top position */
    transform: none; /* Reset transform */
    width: 70%; /* Full width for mobile screens */
    margin: 20px auto; /* Center the form horizontally */
  }

  .inputFields {
    font-size: 0.9em;
  }

  .heading,
  .login {
    font-size: 1.8em;
    padding: 8px 50px;
  }
}

@media only screen and (max-device-width: 1024px) and (max-device-height: 600px) {
  .form_maine {
    position: relative; /* Make the form position relative on smaller screens */
    right: auto; /* Reset right position */
    top: auto; /* Reset top position */
    transform: none; /* Reset transform */
    width: 90%; /* Full width for mobile screens */
    margin: 20px auto; /* Center the form horizontally */
  }

  .inputFields {
    font-size: 0.9em;
  }

  .heading,
  .login {
    font-size: 1.8em;
    padding: 8px 50px;
  }
}
