@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.App-header {
  /* height: 200vh; */
  width: 100vw;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
  background-color: white;
}

.back {
  height: 110vh;
  width: 100vw;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.intro {
  position: absolute;
  top: 38%;
  text-align: start;
  left: 10%;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 75px;
  text-transform: none;
  letter-spacing: -0.018em;
  line-height: 1.2;
  font-weight: 900; /* ou font-weight: 700; */
  z-index: 1;
}

/* Responsive Adjustments */

@media only screen and (min-width: 360px) and (max-height: 920px) and (max-width: 1025px) {
  .intro {
    top: 27%;
    font-size: 47px;
  }
}

@media only screen and (min-device-width: 1030px) and (min-device-height: 600px) and (max-device-width: 1400px) {
  .intro {
    top: 35%;
    font-size: 80px;
  }
}

@media only screen and (min-device-width: 1400px) and (min-device-height: 600px) {
  .intro {
    top: 35%;
    font-size: 80px;
  }
}

@media only screen and (min-device-width: 750px) and (min-device-height: 900px) and (max-device-width: 1400px) {
  .intro {
    font-size: 65px;
  }
}
