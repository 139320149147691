/* component.css */

/* Wrapper to center the carousel and limit its width */
.carousel-wrapper {
  max-width: 1000px; /* Maximum width for the carousel */
  margin: 0 auto; /* Center the carousel horizontally */
  padding: 20px; /* Padding around the carousel */
  box-sizing: border-box; /* Ensure padding is included in the width */
  width: 100%; /* Ensure it takes the full width of the container */
}

/* Styling the Carousel container */
.carousel .slide {
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  ); /* Soft gradient background */
  padding: 20px; /* Padding for space around content */
  padding-bottom: 50px; /* Extra padding at the bottom to avoid overlap */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: center; /* Center-align text */
  font-family: "Montserrat", sans-serif; /* Font family for the entire slide */
}

/* Title styling */
.carousel .slide h2 {
  font-size: 28px; /* Larger font size for titles */
  color: #333; /* Darker color for contrast */
  font-weight: 800; /* Heavier font weight */
  margin-bottom: 10px; /* Space below the title */
  text-transform: uppercase; /* Uppercase text for title */
}

/* Subtitle styling */
.carousel .slide h3 {
  font-size: 20px; /* Slightly smaller font size for subtitles */
  color: #555; /* Slightly lighter color for contrast */
  font-weight: 600; /* Medium font weight */
  margin-bottom: 20px; /* Space below the subtitle */
}

/* Image styling */
.carousel .slide .carousel-image img {
  width: 150px; /* Fixed width for smaller size */
  height: 150px; /* Fixed height for smaller size */
  border-radius: 50%; /* Round shape */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  margin-bottom: 20px; /* Space below the image */
  border: 2px solid #ddd; /* Optional: border around the image */
}

/* Content styling */
.carousel .slide p {
  font-size: 16px; /* Standard font size */
  color: #666; /* Medium-dark color for content */
  margin-bottom: 20px; /* Space below the content */
  padding: 10px; /* Padding around content */
  border-top: 1px solid #ddd; /* Light top border to separate from title/subtitle */
  border-bottom: 1px solid #ddd; /* Light bottom border to separate from button */
  font-weight: 400; /* Regular font weight */
}

/* Button styling */
.carousel .slide button {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: white; /* White text */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Padding for button */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
}

.carousel .slide button:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darker background on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Modal styling */
.ReactModal__Overlay {
  background-color: rgba(
    0,
    0,
    0,
    0.75
  ) !important; /* Dark semi-transparent background */
}

.ReactModal__Content {
  background: #f8d748 !important; /* Bright yellow background */
  padding: 40px; /* Padding inside modal */
  max-width: 800px; /* Maximum width for modal */
  margin: auto; /* Center modal */
  border-radius: 20px; /* More rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  position: relative; /* Relative positioning for inner elements */
  text-align: center; /* Center-align text */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Modal Title styling */
.ReactModal__Content h2 {
  font-size: 28px; /* Larger font size for modal title */
  margin-bottom: 20px; /* Space below the title */
  font-family: "Montserrat", sans-serif; /* Font family for the modal title */
  font-weight: 800; /* Heavier font weight */
  color: #333; /* Darker color for contrast */
  text-transform: uppercase; /* Uppercase text for title */
}

/* Modal Subtitle styling */
.ReactModal__Content h3 {
  font-size: 24px; /* Slightly smaller font size for modal subtitle */
  color: #333; /* Darker color for contrast */
  margin-bottom: 20px; /* Space below the subtitle */
  font-family: "Montserrat", sans-serif; /* Font family for the modal subtitle */
  font-weight: 600; /* Medium font weight */
}

/* Modal Image styling */
.ReactModal__Content .modal-image img {
  width: 150px; /* Fixed width for smaller size */
  height: 150px; /* Fixed height for smaller size */
  border-radius: 50%; /* Round shape */
  object-fit: cover; /* Ensure the image covers the area without distortion */
  margin-bottom: 20px; /* Space below the image */
  border: 2px solid #333; /* Border around the image for contrast */
}

/* Modal Content styling */
.ReactModal__Content p {
  font-size: 18px; /* Standard font size for modal content */
  color: #333; /* Darker color for contrast */
  margin-bottom: 20px; /* Space below the content */
  line-height: 1.6; /* Improved readability */
  font-family: "Montserrat", sans-serif; /* Font family for the modal content */
  padding: 10px; /* Padding around content */
  border-top: 2px solid #ddd; /* Slightly wider top border to separate from title/subtitle */
  border-bottom: 2px solid #ddd; /* Slightly wider bottom border to separate from button */
  font-weight: 400; /* Regular font weight */
}

/* Close button styling */
.ReactModal__Content button {
  background-color: #000; /* Black button background */
  color: #fff; /* White text */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Padding for button */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  font-weight: 700; /* Increase font weight */
  margin-top: 20px; /* Space above the button */
  width: 125px;
}

.ReactModal__Content button:hover {
  background-color: #333; /* Darker background on hover */
}

/* Media queries for smaller screens */
@media (max-width: 1000px) {
  .carousel-wrapper {
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .carousel .slide {
    padding: 10px; /* Adjust padding for smaller screens */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carousel .slide h2 {
    font-size: 5vw; /* Responsive font size */
  }

  .carousel .slide h3 {
    font-size: 4vw; /* Responsive font size */
  }

  .carousel .slide p {
    font-size: 3.5vw; /* Responsive font size */
  }

  .carousel .slide .carousel-image img {
    width: 20vw; /* Responsive image width */
    max-width: 100px; /* Maximum width */
    height: auto; /* Maintain aspect ratio */
  }

  .ReactModal__Content {
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .ReactModal__Content h2 {
    font-size: 5vw; /* Responsive font size */
  }

  .ReactModal__Content h3 {
    font-size: 4vw; /* Responsive font size */
  }

  .ReactModal__Content p {
    font-size: 3vw; /* Responsive font size */
  }

  .ReactModal__Content .modal-image img {
    width: 20vw; /* Responsive image width */
    max-width: 100px; /* Maximum width */
    height: auto; /* Maintain aspect ratio */
  }

  .ReactModal__Content button {
    width: 30vw; /* Adjust button width */
    max-width: 100px; /* Maximum width */
    padding: 10px; /* Adjust button padding */
  }
}

@media (max-width: 768px) {
  .carousel-wrapper {
    padding: 5px;
    width: 350px; /* Further adjust padding for smaller screens */
  }

  .carousel .slide {
    padding: 5px; /* Further adjust padding for smaller screens */
  }

  .carousel .slide h2 {
    font-size: 3.5vw; /* Further adjust font size for smaller screens */
  }

  .carousel .slide h3 {
    font-size: 3vw; /* Further adjust font size for smaller screens */
  }

  .carousel .slide p {
    font-size: 2vw; /* Further adjust font size for smaller screens */
  }
  .carousel .slide button {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    color: white; /* White text */
    border: none; /* Remove default border */
    padding: 10px 20px; /* Padding for button */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effect */
    margin-bottom: 40px;
  }

  .carousel .slide .carousel-image img {
    width: 20vw; /* Further adjust image width */
    /* max-width: 80px; Maximum width */
    height: auto; /* Maintain aspect ratio */
  }

  .ReactModal__Content {
    padding: 10px; /* Further adjust padding for smaller screens */
  }

  .ReactModal__Content h2 {
    font-size: 6vw; /* Further adjust font size for smaller screens */
  }

  .ReactModal__Content h3 {
    font-size: 4vw; /* Further adjust font size for smaller screens */
  }

  .ReactModal__Content p {
    font-size: 3vw; /* Further adjust font size for smaller screens */
  }

  .ReactModal__Content .modal-image img {
    width: 20vw; /* Further adjust image width */
    max-width: 80px; /* Maximum width */
    height: auto; /* Maintain aspect ratio */
  }

  .ReactModal__Content button {
    width: 40vw; /* Adjust button width */
    max-width: 80px; /* Maximum width */
    padding: 5px; /* Adjust button padding */
  }
}

@media (max-width: 480px) {
  .carousel-wrapper {
    padding: 5px; /* Further adjust padding for smallest screens */
  }

  .carousel .slide {
    padding: 5px; /* Further adjust padding for smallest screens */
  }

  .carousel .slide h2 {
    font-size: 5vw; /* Further adjust font size for smallest screens */
  }

  .carousel .slide h3 {
    font-size: 4vw; /* Further adjust font size for smallest screens */
  }

  .carousel .slide p {
    font-size: 4vw; /* Further adjust font size for smallest screens */
  }

  .carousel .slide .carousel-image img {
    width: 10vw; /* Further adjust image width */
    max-width: 70px; /* Maximum width */
    height: auto; /* Maintain aspect ratio */
  }

  .ReactModal__Content {
    padding: 10px; /* Further adjust padding for smallest screens */
  }

  .ReactModal__Content h2 {
    font-size: 6vw; /* Further adjust font size for smallest screens */
  }

  .ReactModal__Content h3 {
    font-size: 5vw; /* Further adjust font size for smallest screens */
  }

  .ReactModal__Content p {
    font-size: 4vw; /* Further adjust font size for smallest screens */
  }

  .ReactModal__Content .modal-image img {
    width: 20vw; /* Further adjust image width */
    max-width: 70px; /* Maximum width */
    height: auto; /* Maintain aspect ratio */
  }

  .ReactModal__Content button {
    width: 50vw; /* Adjust button width */
    max-width: 70px; /* Further adjust button width */
    padding: 5px; /* Further adjust button padding */
  }
}
