.signatureCanva{
  position: absolute;
    left: 30%;
    TOP: 70%;
}

.logo-onesongs {
  width: 350px;
  height: auto;
  position: absolute;
  left: 30%;
}

.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  line-height: 1.7;
}

.checkboxContainer .checkmark {
  position: absolute;
  top: 10%;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
}

.checkboxContainer input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkboxContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

.checkboxContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.required-field {
  display: flex;
  align-items: center;
}

.red-asterisk {
  color: red;
}

.required-symbol {
  color: red;
  margin-right: 4px;
}

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    max-width: 1000px;
    background-color: #fff;
   
    border-radius: 20px;
  }
  
  .title {
    font-size: 28px;
    color: royalblue;
    font-weight: 600;
    letter-spacing: -1px;
    position: relative;
    left: 10px;
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .title::before,
  .title::after {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    border-radius: 50%;
    left: -25px;
    background-color: royalblue;
  }
  
  .title::before {
    width: 18px;
    height: 18px;
    background-color: royalblue;
  }
  
  .title::after {
    width: 18px;
    height: 18px;
    animation: pulse 1s linear infinite;
  }
  
  .message,
  .signin {
    color: rgba(88, 87, 87, 0.822);
    font-size: 14px;
    text-align: center;
  }
  
  .signin a {
    color: royalblue;
  }
  
  .signin a:hover {
    text-decoration: underline;
  }
  
  .principal {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    display: flex;
    border: 3px solid #fdfeff;
    justify-content: space-between;
  }
  
  .flex {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 15px;
  }
  
  .flexOne {
    display: flex;
    width: 100%;
   
  }
  
  .flexOne label {
    width: 100%;
  }
   
  .flexThree {
    display: inline-block;
    width: 100%;
  }
  
  .flexThree label {
    width: 100%;
  }
  
  .flexForth {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  
  .flexForth label {
    width: 100%;
    padding: 0.5%;
    text-align: center;
  }
  
  .form label {
    position: relative;
    text-align: center;
  }
  
  .form label .input {
    width: 100%;
    padding: 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }
  
  .form label .input + span {
    position: absolute;
    left: 10px;
   
    color: grey;
    font-size: 0.9em;
    cursor: text;
    transition: 0.3s ease;
  }
  
  .form label .input:placeholder-shown + span {
    top: 15px;
    font-size: 0.9em;
  }
  
  .form label .input:focus + span,
  .form label .input:valid + span {
    top: 0px;
    font-size: 0.7em;
    font-weight: bold;
  }

  .form label .inpt:focus + span,
  .form label .inpt:valid + span {
    top: 30px;
    font-size: 0.7em;
    font-weight: bold;
  }
  .form label .inpt {
    width: 100%;
    padding: 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }
  
  .form label .inpt + span {
    position: absolute;
    left: 10px;
    top: 15px;
    color: grey;
    font-size: 0.9em;
    cursor: text;
    transition: 0.3s ease;
  }
  
  .form label .inpt:placeholder-shown + span {
    top: 15px;
    font-size: 0.9em;
  }

  .submit {
    border: none;
    outline: none;
    background-color: royalblue;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    transform: .3s ease;
  }
  
  .submit:hover {
    background-color: rgb(56, 90, 194);
  }
  
  @keyframes pulse {
    from {
      transform: scale(0.9);
      opacity: 1;
    }
  
    to {
      transform: scale(1.8);
      opacity: 0;
    }
  }


 

 .search {
    display: inline-block;
    width: 100%;
  --input-line: #cccccc;
  --input-text-color: #808080;
  --input-text-hover-color: transparent;
  --input-border-color: #808080;
  --input-border-hover-color: #999999;
  --input-bg-color: #ffffff;
  --search-max-width: 250px;
  --search-min-width: 150px;
  --border-radius: 5px;
  --transition-cubic-bezier: 150ms cubic-bezier(0.4,0,0.2,1);
}

.search-box {
  max-width: var(--search-max-width);
  min-width: var(--search-min-width);
  height: 35px;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius);
  padding: 5px 15px;
  background: var(--input-bg-color);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transition: var(--transition-cubic-bezier);
}

.search-box:hover {
  border-color: var(--input-border-hover-color);
}

.search-field {
  position: relative;
  width: 100%;
  height: 100%;
  left: 5px;
  border: 0;
}

.input {
  width: calc(100% - 29px);
  height: 100%;
  border: 0;
  border-color: transparent;
  font-size: 1rem;
  padding-right: 0px;
  color: var(--input-line);
  background: var(--input-bg-color);
  border-right: 2px solid var(--input-border-color);
  outline: none;
}

.input::-webkit-input-placeholder {
  color: var(--input-text-color);
}

.input::-moz-input-placeholder {
  color: var(--input-text-color);
}

.input::-ms-input-placeholder {
  color: var(--input-text-color);
}

.input:focus::-webkit-input-placeholder {
  color: var(--input-text-hover-color);
}

.input:focus::-moz-input-placeholder {
  color: var(--input-text-hover-color);
}

.input:focus::-ms-input-placeholder {
  color: var(--input-text-hover-color);
}


.search-box-icon {
  width: 52px;
  height: 35px;
  position: absolute;
  top: -6px;
  right: -21px;
  background: transparent;
  border-bottom-right-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  transition: var(--transition-cubic-bezier);
}

.search-box-icon:hover {
  background: var(--input-border-color);
}

.btn-icon-content {
  width: 52px;
  height: 35px;
  top: -6px;
  right: -21px;
  background: transparent;
  border: none;
  cursor: pointer;
  border-bottom-right-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  transition: var(--transition-cubic-bezier);
  opacity: .4;
}

.btn-icon-content:hover {
  opacity: .8;
}

.search-icon {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 7px;
  right: 15px;
}

#lang-select{
  width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
}

#textmusic {
width: 100%;
  padding: 10px 10px 20px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 10px;
}

#distribcate {
  width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }

#version {
  width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }

#arrangement {
  width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }

#lyrics{
  width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }
  
#composite{
  width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }

#ccc{
  width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }
#cwr{
  width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }

#lyrics {
  width: 100%;
    padding: 10px 10px 20px 10px;
    outline: 0;
    border: 1px solid rgba(105, 105, 105, 0.397);
    border-radius: 10px;
  }


  
  #submit {
    position: relative;
    background: #6F6FF0;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    letter-spacing: 0.1rem;
    font-size: 1rem;
    padding: 1rem 3rem;
    transition: 0.2s;
    border-radius: 10rem;
  }
  
  #submit:hover {
    letter-spacing: 0.2rem;
    padding: 1.1rem 3.1rem;
    background: var(--clr);
    color: var(--clr);
    animation: box 3s infinite;
  }
  
  #submit::before {
    content: "";
    position: absolute;
    inset: 2px;
    background: #6F6FF0;
    border-radius: 10rem;
  }
  
  #submit span {
    position: relative;
    z-index: 1;
  }
  #submit i {
    position: absolute;
    inset: 0;
    display: block;
  }
  
  #submit i::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 80%;
    top: -2px;
    border: 2px solid var(--clr);
    background: #6F6FF0;
    transition: 0.2s;
  }
  
  #submit:hover i::before {
    width: 15px;
    left: 20%;
    animation: move 3s infinite;
  }
  
  #submit i::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 2px;
    left: 20%;
    bottom: -2px;
    border: 2px solid var(--clr);
    background: #6F6FF0;
    transition: 0.2s;
  }
  
  #submit:hover i::after {
    width: 15px;
    left: 80%;
    animation: move 3s infinite;
  }
  
  @keyframes move {
    0% {
      transform: translateX(0);
    }
    50% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes box {
    0% {
      box-shadow: #8A2BE2;
    }
    50% {
      box-shadow: 0 0 25px var(--clr);
    }
    100% {
      box-shadow: #8A2BE2;
    }
  }



#lesparoles {
  font-weight:600;
}





.form-label.form-label-auto {
  display: block;
  float: none;
  text-align: left;
  width: 100%;
  font-size: 12px;
}

.form-line {
  margin-top: 6px;
  margin-bottom: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

.form-all {
  max-width: 376px;
  width: 100%;
  font-size: 12px;
}

.form-label.form-label-left,
.form-label.form-label-right,
.form-label.form-label-left.form-label-auto,
.form-label.form-label-right.form-label-auto {
  width: 115px;
}

.form-all {
  color: #6F6FF0;
}

.supernova .form-all,
.form-all {
  background-color: #fff;
}

.form-header-group .form-header {
  color: #6F6FF0;
}

.form-header-group .form-subHeader {
  color: #6F6FF0;
}

.form-label-top,
.form-label-left,
.form-label-right,
.form-html,
.form-checkbox-item label,
.form-radio-item label,
span.FITB .qb-checkbox-label,
span.FITB .qb-radiobox-label,
span.FITB .form-radio label,
span.FITB .form-checkbox label,
[data-blotid][data-type=checkbox] [data-labelid],
[data-blotid][data-type=radiobox] [data-labelid],
span.FITB-inptCont[data-type=checkbox] label,
span.FITB-inptCont[data-type=radiobox] label {
  color: #6F6FF0;
}

.form-sub-label {
  color: #464d5f;
}

.supernova {
  background-color: #f3f3fe;
}

.supernova body {
  background: transparent;
}

.form-textbox,
.form-textarea,
.form-dropdown,
.form-radio-other-input,
.form-checkbox-other-input,
.form-captcha input,
.form-spinner input {
  background-color: #fff;
}

.supernova {
  background-image: none;
}

#stage {
  background-image: none;
}

.form-all {
  background-image: none;
}

.form-all {
  position: relative;
}

.form-all:before {
  content: "";
  background-image: url("https://www.jotform.com/uploads/mikaelyandriapro/form_files/logo.649161bf1852c8.74011987.png");
  display: inline-block;
  height: 100px;
  position: absolute;
  background-size: 100px 100px;
  background-repeat: no-repeat;
  width: 100%;
}

.form-all {
  margin-top: 120px;
}

.form-all:before {
  top: -110px;
  background-position: top center;
  left: 0;
}

.checkbox-text {
  margin-left: 8px; /* Adjust the value as needed */
}



#jotform-form{
  margin-right: 10em;
  width: 800px; /* Adjust the width as needed */
  height: 600px; /* Adjust the height as needed */
  overflow-y: auto; /* Add scrollbars when content exceeds the dimensions */
}



  @media print {
    .form-section {
      display: inline !important;
    }
    .form-pagebreak {
      display: none !important;
    }
    .form-section-closed {
      height: auto !important;
    }
    .page-section {
      position: initial !important;
    }
  }

  /* Add your media queries here */
  @media only screen and (max-width: 768px) {
    /* Styles for screens up to 768px width */
    /* Adjust your responsive styles here */
    .form-all {
      /* Example responsive style */
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 480px) {
    /* Styles for screens up to 480px width */
    /* Adjust your responsive styles here */
    .form-all {
      /* Example responsive style */
      font-size: 12px;
    }
  }
