.centered-div {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 40%;
  }
  
  .white-text {
    color: white;
  }
  