.Title {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 0%;
    left: 3.5%;
    width: 150px;
    height: auto;
  }
  
  .page-container {
    height: 1000px;
    width: 18%;
    background-color: rgb(111, 111, 240);
  }
  
  .register {
    position: absolute;
    left: 35%;
  }
  
  .cta {
    position: absolute;
    top: 40%;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
  }
  
  .cta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: rgb(125, 125, 253);
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
  }
  
  .cta span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #234567;
  }
  
  .cta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #234567;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
  }
  
  .cta:hover:before {
    width: 100%;
    background: rgb(125, 125, 253);
  }
  
  .cta:hover svg {
    transform: translateX(0);
  }
  
  .cta:active {
    transform: scale(0.95);
  }
  
  /* List song css */
  
  .listregister {
    position: absolute;
    top: -15%;
    left: 32%;
    width: 600px;
  }
  
  .listcta {
    position: absolute;
    top: 55%;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
  }
  
  .listcta:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    background: #d8b3ee;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
  }
  
  .listcta span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
    color: #234567;
  }
  
  .listcta svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: #234567;
    stroke-width: 2;
    transform: translateX(-5px);
    transition: all 0.3s ease;
  }
  
  .listcta:hover:before {
    width: 100%;
    background: #e7b1e3;
  }
  
  .listcta:hover svg {
    transform: translateX(0);
  }
  
  .listcta:active {
    transform: scale(0.95);
  }
  
  /* Responsive Adjustments */
  @media only screen and (min-device-width: 1020px) and (min-device-height: 600px) and (max-device-width: 1400px){
    .Title{
      left: 3.5%;
      width: 89px;
    }
  }


  @media only screen and (min-width:360px) and (max-height:920px) and (max-width:1019px){
    .listregister{
      top: -16%;
      left: 13%;
      width: 300px;
    }
    
    .page-container{
      width: 0%;
    }

    .Title{
      left: 3.5%;
      width: 89px;
    }
  }

  @media only screen and (min-device-width: 750px) and (min-device-height: 900px) and (max-device-width: 1400px){
    .listregister{
      top: -16%;
      left: 13%;
    }
    
    .page-container{
      width: 0%;
    }

    .Title{
      left: 3.5%;
      width: 50px;
    }
  }


  body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    color: #333;
}
.zeheader {
    width: 100%;
    padding: 0px 35px;
    background: #fff;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-bottom: 2px solid #e0e0e0;
     position: fixed;
    top: 0;
    z-index: 1000;
}
.zeheader img {
    height: 80px;
}
.user-info {
    display: flex;
    align-items: center;
    margin-left: auto;
    position: relative;
}

.user-infoo {
  /* display: flex; */
  align-items: center;
  margin-left: auto;
  position: relative;
}
.user-initials {
    width: 40px;
    height: 40px;
    background-color: #7D4EDA;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    margin-left: 20px;
    margin-right: 40px;
}
.notification {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 24px;
}
.notification .icon {
    margin-right: 10px;
    position: relative;
    color: #7D4EDA;
}
.notification .icon .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 5px;
    font-size: 12px;
}
.user-dropdown {
    display: none;
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 250px;
    z-index: 1000;
}
.user-dropdown.active {
    display: block;
    right: 20px;
}
.user-dropdown .user-infoo {
    padding: 15px;
    border-bottom: 1px solid #eee;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
}
.user-dropdown .user-infoo .name {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}
.user-dropdown .user-infoo .email {
    color: #777;
    font-size: 14px;
    display: block;
    margin-top: 10px;
}
.user-dropdown a {
    display: block;
    padding: 10px 15px;
    color: #333;
    text-decoration: none;
    transition: background 0.3s;
}
.user-dropdown a:hover {
    background-color: #f4f4f9;
}
.sidebar {
    width: 200px;
    background: #F3F2F8;
    padding: 20px;
    color: #000;
    height: 100vh;
    position: fixed;
    top: 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}
.sidebar h1 {
    margin: 0 0 20px 0;
    font-size: 14px; /* Réduire la taille de la police */
    color: #333;
    padding: 10px;
    font-weight: 900;
    text-transform: uppercase;
    width: 113%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff; /* Changer le fond en blanc */
    border: 1px solid #333; /* Ajouter un léger contour noir */
    border-right: none; /* Enlever le bord droit */
    white-space: nowrap; /* Forcer l'affichage sur une seule ligne */
}
.sidebar h1 span {
    margin-left: 10px;
    font-size: 14px; /* Ajuster la taille de la police du span */
}
.sidebar ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
}
.sidebar ul li {
    margin: 10px 0; /* Réduction de l'espacement entre les éléments */
    display: flex;
    align-items: center;
}
.sidebar ul li a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    padding: 10px;
    border-radius: 0;
    flex-grow: 1;
    font-weight: 450;
    display: flex;
    align-items: center;
    transition: background 0.3s ease, transform 0.3s ease;
}
.sidebar ul li a:hover {
    background-color: rgba(125, 78, 218, 0.1);
    transform: translateX(5px);
}
.sidebar ul li svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    fill: #7D4EDA;
}
.sidebar ul li.active a {
    background-color: rgba(125, 78, 218, 0.1);
}
.content {
    margin-left: 270px;
    padding: 80px 40px 40px;
    width: calc(100% - 270px);
    background-color: #FFFFFF; /* Changer le fond en blanc */
}
.content h2 {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: 900;
}
.content p {
    color: #555;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    font-weight: 400;
}
.action-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
}
.register-work-btn {
    display: inline-block;
    padding: 10px 20px;
    background: #7D4EDA;
    color: #fff;
    text-decoration: none;
    border-radius: 0;
    /* margin-bottom: 20px; */
    transition: background 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    padding-right: 40px;
    white-space: nowrap;
}
.register-work-btn:hover {
    background: #6C3EB8;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}
.register-work-btn::after {
    content: '→';
    position: absolute;
    right: 15px;
    font-size: 16px;
    top: 50%;
    transform: translateY(-50%);
}
.search-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    max-width: 600px;
}
.search-bar {
    padding: 10px 15px;
    border: 1px solid #333; /* Ajouter un contour noir fin */
    border-radius: 0;
    /* width: 100%; */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-size: 16px;
}
.search-btn {
    padding: 10px 20px;
    background: #7D4EDA;
    color: #fff;
    text-decoration: none;
    border-radius: 0;
    transition: background 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #333; /* Ajouter un contour noir fin */
    cursor: pointer;
    margin-left: 10px;
}
.search-btn:hover {
    background: #6C3EB8;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}
.song-count-btn {
    display: inline-block;
    padding: 10px 15px;
    background: #e0dcf7;
    color: #7D4EDA;
    text-decoration: none;
    border: 2px solid #7D4EDA;
    border-radius: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
    position: fixed;
    top: 20px;
    right: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.song-count {
    background: #7D4EDA;
    color: #fff;
    padding: 5px 10px;
    border-radius: 0;
    margin-left: 10px;
}
.contenue-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: calc(90% - 200px); */
    margin: 0 auto;
}
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    overflow: hidden;
}
th, td {
    text-align: left;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}
th {
    background-color: #f4f4f4;
    color: #555;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 600;
    border-bottom: 1px solid #ddd;
    position: relative;
}
th .sort-icon {
    font-size: 12px;
    margin-left: 5px;
    visibility: hidden;
}
th:hover .sort-icon {
    visibility: visible;
}
th.asc .sort-icon::before {
    content: "▲";
    color: #7D4EDA;
}
th.desc .sort-icon::before {
    content: "▼";
    color: #7D4EDA;
}
tr:hover {
    background-color: #f0f0f0;
    transition: background-color 0.3s ease;
}
.status.pending::before,
.status.registration::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%237D4EDA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 1a11 11 0 1 1-11 11A11.013 11.013 0 0 1 12 1m0-1a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0z"/><path d="M8 12h8M12 8v8"/></svg>') no-repeat center center;
    margin-right: 5px;
}
.status.pending {
    color: #7D4EDA;
}
.status.registration {
    color: #7D4EDA;
}
.status.registered::before {
    content: '\2714';
    margin-right: 5px;
    color: #7D4EDA;
}
.status.registered {
    color: #7D4EDA;
}
.song-title a {
    font-weight: 600;
    color: #7D4EDA;
    text-decoration: none;
    font-size: 16px;
}
.song-title a:hover {
    text-decoration: underline;
}
.song-row td {
    padding: 15px 15px;
}
@media (max-width: 768px) {
    .zeheader {
        padding: 10px;
    }
    .user-info {
        margin-left: auto;
        margin-right: 10px;
    }
    .content {
        margin-left: 0;
        padding: 80px 20px 20px;
        width: 100%;
    }
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
        box-shadow: none;
    }
    .sidebar h1 {
        font-size: 14px;
    }
    .sidebar ul li a {
        font-size: 16px;
    }
    .sidebar ul li svg {
        width: 20px;
        height: 20px;
    }
    .action-container {
        flex-direction: column;
        align-items: stretch;
    }
    .search-container {
        margin-top: 10px;
        max-width: 100%;
    }
}
.pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-top: 1px solid #ddd;
    margin-top: 20px;
    width: 100%;
}
.pagination {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-start;
}
.pagination-button,
.pagination-page {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #333;
    color: #333;
    cursor: pointer;
    border-radius: 0;
}
.pagination-button:hover,
.pagination-page:hover,
.results-option:hover {
    background-color: #ddd;
}
.pagination-page.active,
.results-option.active {
    background-color: #7D4EDA;
    color: white;
    border: none;
}
.results-per-page {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
}
.results-per-page span {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #333;
    color: #333;
    cursor: pointer;
    border-radius: 0;
}
.results-per-page span:hover {
    background-color: #ddd;
}
.results-option.active {
    background-color: #7D4EDA;
    color: white;
    border: none;
}

.popup {
    position: absolute;
    right: 16%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 200px;
    height: 70px;

  }
  
  .popup-content {
    position: relative;
    text-align: center;
  }
  
  .popup .close {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 16px;
    cursor: pointer;
  }


  .notif-box {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .notif-content {
    position: relative;
    background: #fff;
    width: 300px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-align: center;
  }
  
  .closerbouton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 18px;
  }