.inputgroup {
  position: absolute;
  top: 45%;
  left: 40%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
 
}
.inpute {
  min-height: 50px;
  max-width: 150px;
  padding: 0 1rem;
  color: #fff;
  font-size: 15px;
  border: 2px solid #5e4dcd;
  border-radius: 6px 0 0 6px;
  background-color: transparent;
}

.button--submit {
  min-height: 50px;
  padding: .5em 1em;
  border: none;
  border-radius: 0 6px 6px 0;
  background-color: #5e4dcd;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background-color .3s ease-in-out;
}

.button--submit:hover {
  background-color: #5e5dcd;
}

.input:focus,
.input:focus-visible {
  border-color: #3898EC;
  outline: none;
}

.recover {
  color: white;
  position: absolute;
  left: 33%;
  top: 40%;
  font-size: 25px;
}

/* Responsive Adjustments */

@media only screen and (min-device-width: 1030px) and (min-device-height: 600px) and (max-device-width: 1400px){
  .inputgroup{
    transform: scale(1.6);
  }
}

@media only screen and (min-device-width: 1400px) and (min-device-height: 600px) {

  .inputgroup{
    transform: scale(1.1);
  }

}

@media only screen and (min-width:360px) and (max-height:900px) and (max-width:1025px){
  .inputgroup{
    transform: scale(1);
    left: 30%;
  }

}



/*card css*/

.card {
  width: 190px;
  height: 254px;
  transition: all 0.2s;
  position: relative;
  cursor: pointer;
}

.card-inner {
  width: inherit;
  height: inherit;
  background: rgba(255,255,255,.05);
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
  backdrop-filter: blur(10px);
  border-radius: 8px;
}

.card:hover {
  transform: scale(1.04) rotate(1deg);
}

.circle {
  width: 100px;
  height: 100px;
  background: radial-gradient(#b0e633, #53ef7d);
  border-radius: 50%;
  position: absolute;
  animation: move-up6 2s ease-in infinite alternate-reverse;
}

.circle:nth-child(1) {
  top: -25px;
  left: -25px;
}

.circle:nth-child(2) {
  bottom: -25px;
  right: -25px;
  animation-name: move-down1;
}

@keyframes move-up6 {
  to {
    transform: translateY(-10px);
  }
}

@keyframes move-down1 {
  to {
    transform: translateY(10px);
  }
}