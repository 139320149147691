.status.pending::before,
.status.registration::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%237D4EDA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M12 1a11 11 0 1 1-11 11A11.013 11.013 0 0 1 12 1m0-1a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0z"/><path d="M8 12h8M12 8v8"/></svg>') no-repeat center center;
    margin-right: 5px;
}

.status.pending {
    color: #7D4EDA;
}

.status.registration {
    color: #7D4EDA;
}

.status.registered::before {
    content: '\2714';
    margin-right: 5px;
    color: #7D4EDA;
}

.status.registered {
    color: #7D4EDA;
}

/* Styles pour les titres de chansons */
.song-title a {
    font-weight: 600;
    color: #7D4EDA;
    text-decoration: none;
    font-size: 16px;
}

.song-title a:hover {
    text-decoration: underline;
}

/* Styles pour les lignes de chansons */
.song-row td {
    padding: 15px 15px;
}

/* Conteneur principal */
.songListContainer {
    max-width: 100%;
    margin: 0 auto;
}

/* Conteneur de la table de chansons */
.songTableContainer {
    max-height: 100%;
    max-width: 100%;
    /* width: 55vw;
    overflow-y: auto; */
    position: relative;
}

/* En-tête */
.header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    padding: 5px;
    border-radius: 8px;
    color: #6f6ff0;
    gap: 8px;
    margin-top: 10px;
}

/* Icônes */
.icon {
    margin-right: 5px;
}

/* Titre principal */
.heading {
    font-size: 20px;
    margin-right: 10px;
    color: #ffffff;
}


/* Champ de recherche */
.searchInput {
  padding: 10px 15px;
  border: 1px solid #333; /* Ajouter un contour noir fin */
  border-radius: 0;
  width: 100%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
}

/* Table de chansons */
.songTable {
    width: 100%;
    border-collapse: collapse;
}

.songTable th {
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 8px;
    text-align: left;
    color: blue;
    border-bottom: 1px solid #ccc;
    border-radius: 8px;
}

.songTable td {
    font-size: 20px;
    font-weight: 900;
    padding: 8px;
    cursor: pointer;
    color: #000000;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
}

/* Responsiveness */
@media only screen and (min-width:360px) and (max-height:920px) and (max-width:1019px){
    .heading {
        margin-bottom: 10px;
        font-size: 15px;
        top: 10%;
        position: fixed;
    }

    .songTable th {
        font-size: 12px;
    }

    .searchInput {
        margin-top: 0;
        position: fixed;
        top: 20%;
        left: 66%;
        width: 29%;
    }

    .songTableContainer {
        width: 78vw;
    }

    .sortContainer {
        position: relative;
        margin-left: 0;
        margin-top: 20px;
    }

    .sortButton {
        position: unset;
        top: unset;
        right: unset;
    }

    .sortDropdown {
        position: unset;
        top: unset;
        right: unset;
        width: 100%;
        text-align: center;
        margin-top: 40px;
        margin-left: 18px;
    }

    .sortDropdownWrapper {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .sortButtonWrapper {
        margin-left: 0;
        margin-bottom: 5px;
    }

    .songListContainer {
        width: 100%;
        /* margin-top: 115%; */
    }
}

@media only screen and (min-device-width: 1020px) and (min-device-height: 600px) and (max-device-width: 1400px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .heading {
        margin-bottom: 10px;
    }

    .searchInput {
        margin-top: 0;
    }



    .sortContainer {
        position: relative;
        margin-left: 0;
        margin-top: 20px;
    }

    .sortButton {
        position: unset;
        top: unset;
        right: unset;
    }

    .sortDropdown {
        position: unset;
        top: unset;
        right: unset;
        width: 100%;
        text-align: center;
        margin-top: 40px;
        margin-left: 18px;
    }

    .sortDropdownWrapper {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .sortButtonWrapper {
        margin-left: 0;
        margin-bottom: 5px;
    }

    .songListContainer {
        width: 100%;
        margin-top: 25%;
    }
}

@media only screen and (min-device-width: 1400px) and (min-device-height: 600px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
    }

    .heading {
        margin-bottom: 10px;
    }

    .searchInput {
        margin-top: 0;
    }



    .sortContainer {
        position: relative;
        margin-left: 0;
        margin-top: 20px;
    }

    .sortButton {
        position: unset;
        top: unset;
        right: unset;
    }

    .sortDropdown {
        position: unset;
        top: unset;
        right: unset;
        width: 100%;
        text-align: center;
        margin-top: 40px;
        margin-left: 18px;
    }

    .sortDropdownWrapper {
        margin-right: 0;
        margin-bottom: 5px;
    }

    .sortButtonWrapper {
        margin-left: 0;
        margin-bottom: 5px;
    }

    .songListContainer {
        width: 100%;
        margin-top: 2%;
    }
}

.sortButton {
    background-color: #6f6ff0;
    color: white;
    padding: 1px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}

.sortButton:hover {
    background-color: #5c5cbf;
}

.sortDropdown {
    background-color: #f1f1f1;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
}

.sortDropdownWrapper {
    margin-right: 8px;
}

.sortButtonWrapper {
    margin-left: 8px;
}

.songSubtitle {
    font-size: 14px;
    margin-bottom: 8px;
    text-align: center;
}

@media only screen and (min-device-width: 750px) and (min-device-height: 900px) and (max-device-width: 1400px) {
    .heading {
        margin-bottom: 10px;
        font-size: 15px;
        top: 7%;
        position: fixed;
    }

    .songTable th {
        font-size: 12px;
    }

    .searchInput {
        margin-top: 0;
        position: fixed;
        top: 9%;
        left: 50%;
        width: 19%;
    }

    .songTableContainer {

        width: 78vw;
    }

    .sortContainer {
        position: relative;
        margin-left: 0;
        margin-top: 20px;
    }

    .sortButton {
        position: unset;
        top: unset;
        right: unset;
    }

    .sortDropdown {
        position: unset;
        top: unset;
        right: unset;
        width: 100%;
        text-align: center;
        margin-top: 40px;
        margin-left: 18px;
    }

    .sortDropdownWrapper {
        margin-right: 0;
        margin-bottom: 5px;
        }.sortButtonWrapper {
          margin-left: 0;
          margin-bottom: 5px;
      }
      
      .songListContainer {
          width: 100%;
          margin-top: 60%;
      }}

      @media only screen and (min-device-width: 1000px) and (min-device-height: 1300px) and (max-device-width: 1100px) {
      .heading {
      margin-bottom: 10px;
      font-size: 15px;
      top: 8%;
      position: fixed;
      }.songTable th {
        font-size: 12px;
    }
    
    .searchInput {
        margin-top: 0;
        position: fixed;
        top: 9%;
        left: 50%;
        width: 19%;
    }
    
    .songTableContainer {

        width: 78vw;
    }
    
    .sortContainer {
        position: relative;
        margin-left: 0;
        margin-top: 20px;
    }
    
    .sortButton {
        position: unset;
        top: unset;
        right: unset;
    }
    
    .sortDropdown {
        position: unset;
        top: unset;
        right: unset;
        width: 100%;
        text-align: center;
        margin-top: 40px;
        margin-left: 18px;
    }
    
    .sortDropdownWrapper {
        margin-right: 0;
        margin-bottom: 5px;
    }
    
    .sortButtonWrapper {
        margin-left: 0;
        margin-bottom: 5px;
    }
    
    .songListContainer {
        width: 100%;
        margin-top: 65%;
    }}