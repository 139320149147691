.contact {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
}

.text-lg-start {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
  position: absolute;
  top: 70%;
  left: 13%;
}

/* Responsive Adjustments */

/* iPhones (portrait and landscape) */
@media only screen 
  and (device-width: 375px) 
  and (device-height: 667px) 
  and (-webkit-device-pixel-ratio: 2) {
  .text-lg-start {
    top: 60%;
  }

  .bg-light {
    top: 55%;
  }
}

/* iPads (portrait and landscape) */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {
  .text-lg-start {
    top: 70%;
  }

  .bg-light {
    top: 65%;
  }
}

/* MacBook */
@media only screen 
  and (min-width: 1280px) 
  and (max-width: 1440px) {
  .text-lg-start {
    top: 80%;
  }

  .bg-light {
    top: 75%;
  }
}

/* PC/Desktop */
@media only screen 
  and (min-width: 1441px) {
  .text-lg-start {
    top: 80%;
  }

  .bg-light {
    top: 70%;
  }
}

/* Responsive Adjustments */

/* iPhones (portrait and landscape) */
@media only screen and (max-width: 767px) {
  .bg-light {
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
}

/* iPads (portrait and landscape) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .bg-light {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
  }
}

/* MacBooks and PCs */
@media only screen and (min-width: 1024px) {
  .bg-light {
    top: 70%;
    left: 13%;
    width: auto;
  }
}
