.logo-container {
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 5%;
  position: absolute;
  z-index: 1;
}

.logo-image {
  width: 250px;
  height: auto;
  position: absolute;
  
}

.logo-dashboard {
  width: 200px;
  height: auto;
  margin-left:4%;
}

.logo-manysongs {
  max-width: 150%;
  height: auto;
  position: absolute;
  left: 30%;
}

.logo {
  width: 200px;
  height: auto;
}

.intro {
  text-align: left;
  font-family: 'Montserrat', sans-serif; 
 
}

.button {
  display: flex;
  justify-content: center;
  color: #fff;
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background: linear-gradient(30deg, rgb(242, 0, 255) 20%, rgb(94, 106, 243) 80%);
  transition: all 0.3s ease-in-out 0s;
  box-shadow: rgba(233, 193, 246, 0.698) 0px 0px 0px 0px;
  align-items: center;
  border: 0;
  position: absolute;
  top: 45%;
  left: 40%;
}

.button:is(:hover, :focus) {
  transform: scale(2);
}

@keyframes pulse {
  100% {
    box-shadow: 0 0 0 45px rgba(193, 244, 246, 0);
  }
}

/* Startup Name */

#startup {
  width: 165px;
  height: 62px;
  cursor: pointer;
  color: #fff;
  font-size: 30px;
  position: absolute;
  left: 45%;
  transition: 0.1s;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Responsive Adjustments */

@media only screen and (max-width: 768px) {
  .logo-container {
    left: 2%;
  }

  .logo-image {
    width: 180px;
    position: absolute;
  }

  .logo-dashboard {
    width: 120px;
    left: 15%;
  }

  .logo {
    width: 150px;
  }

  .button {
    width: 150px;
    height: 150px;
    top: 40%;
    left: 35%;
  }

  #startup {
    font-size: 24px;
    left: 40%;
  }
} 

@media only screen and (max-width: 480px) {
  .logo-container {
    left: 1%;
  }

  .logo-image {
    width: 100px;
    position: absolute;
    margin-left: 5pc;
 

  }

  .logo-dashboard {
    width: 80px;
    left: 12%;
  }

  .logo {
    width: 100px;
  }

  .button {
    width: 120px;
    height: 120px;
    top: 35%;
    left: 30%;
  }

  #startup {
    font-size: 20px;
    left: 35%;
  }
}

@media only screen and (max-device-width: 1024px) and (max-device-height: 600px) {

  .logo-container {
    left: 1%;
  }

  .logo-image {
    width: 140px;
    position: absolute;
    margin-left: 3pc;
 
  }

  .logo-dashboard {
    width: 80px;
    left: 30%;
  }

  .logo {
    width: 100px;
  }

  .button {
    width: 120px;
    height: 120px;
    top: 35%;
    left: 30%;
  }

  #startup {
    font-size: 20px;
    left: 35%;
  }

}


@media only screen and (min-device-width: 750px) and (min-device-height: 900px) and (max-device-width: 1400px){
  .logo-dashboard{
    width: 120px;
  }
}



