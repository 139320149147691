/* Carouselles.css */
.carousel-video{
    height: 645px;
    object-Fit: cover;
    width: 100%;
}



/* secondpage.css */
.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 2.5%;
  
}

.carousel-container .carousel img {
  height: 100%;
  object-fit: contain;
  margin-left: 15%;
}
.carousel-container .carousel video {
  margin-left: 15%;
}

.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7%;
  color: #fff;
  text-align: center;
  opacity: .5;
  transition: opacity .15s ease;
}

.royalties{
  margin-top: 7%;
  width: 100%;
}

.carousele-video{
  height: 897px;
  object-Fit: cover;
  width: 100%;

}

/* For screen size range: (min-device-width: 1030px) and (min-device-height: 600px) and (max-device-width: 1400px) */
@media only screen and (min-device-width: 1030px) and (min-device-height: 600px) and (max-device-width: 1400px){
  .carousel-video {
      width: 100%;
      height: auto;
    }
    .carousele-video{
      width: 100%;
      height: 609px;
    }
  }
  
  /* For screen size range: (min-width:360px) and (max-height:900px) and (max-width:1025px) */
  @media only screen and (min-width:360px) and (max-height:920px) and (max-width:1025px){
    .carousel-video {
      width: 100%;
      height: auto;
    }
    .carousele-video{
      width: 100%;
      height: 100px;
    
    }
  }
  
  /* For screen size range: (min-device-width: 1400px) and (min-device-height: 600px) */
  @media only screen and (min-device-width: 1400px) and (min-device-height: 600px) {
    .carousel-video {
      width: 100%;
      height: auto;
    }

    .carousele-video{
      width: 100%;
      height: 395px;
 
    }
  }


  @media only screen and (min-device-width: 750px) and (min-device-height: 900px) and (max-device-width: 1400px){
    .carousel-video {
      width: 100%;
      height: auto;
    }

    .carousele-video{
      width: 100%;
      height: 200px;
     
    }

  }
  
  @media only screen and (min-device-width: 1020px) and (min-device-height: 600px) and (max-device-width:1040px) and (max-device-height:700px ) {
    .carousel-video {
      width: 100%;
      height: auto;
    }
    .carousele-video{
      width: 100%;
      height: 300px;

    }
  }

  .social-container{
    gap: 10%;
  }