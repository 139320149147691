.file {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
  
  }

  .modele{
    color: white;
    display: block;
    position: absolute;
    top: 50%;
    left: 44%;
  }
  .model{
    position: absolute;
    left: 10%;
    top: 85%;
    color: white;
  }
  
  .file-input label {
    display: block;
    position: absolute;
    top: 45%;
    left: 14%;
    width: 100px;
    height: 50px;
    border-radius: 25px;
    background-color: #6F6FF0 ;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: transform .2s ease-out;
  }
  
  .file-name {
    position: absolute;
    bottom: -35px;
    left: 10px;
    font-size: 0.85rem;
    color: #555;
  }
  
  input:hover + label,
  input:focus + label {
    transform: scale(1.02);
  }
  
  /* j'ajoute un outline et focus ?*/
  input:focus + label {
    outline: 1px solid #000;
    outline: -webkit-focus-ring-color auto 2px;
  }