
.club-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 300px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .club-logo {
    width: 100%;
    height: auto;
    max-height: 150px;
    object-fit: contain;
  }
  
  .club-title {
    font-size: 24px;
    margin: 20px 0 10px;
  }
  
  .club-description {
    font-size: 16px;
    color: #666;
  }
  
  .upload-button {
    background-color: #6C63FF;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .upload-button:hover {
    background-color: #5752d1;
  }