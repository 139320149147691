/* src/CssFiles/Article.css */

.article-container {
  margin: 20px auto;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 700px;
}

.article-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.article-container p {
  font-size: 16px;
  line-height: 1.5;
}
