/* Carousel.css */
.carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px; /* Adjust the height as needed */
  background-color: #f0f0f0; /* Adjust the background color as needed */
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.carousel-slide h2 {
  margin-bottom: 10px;
  font-size: 24px;
}

.carousel-slide p {
  margin-bottom: 20px;
  font-size: 16px;
}

.learn-more-button {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.learn-more-button:hover {
  background-color: #0056b3;
}
