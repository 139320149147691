.popup-overlay2 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content2 {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .formgroup {
    margin-bottom: 15px;
  }
  
  .formgroup label {
    display: block;
    margin-bottom: 5px;
  }
  
  .formgroup input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  #boutoneditpop {
    margin-right: 10px;
    padding: 8px 12px;
    color: #fff;
    background-color: #7D4EDA;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  #boutoneditpop:hover {
    background-color: #693bc8;
  }
  
  #boutoneditpop:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(125, 78, 218, 0.5);
  }
  
  #boutoneditpop:active {
    background-color: #5c32a8;
  }