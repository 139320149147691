.started {
  position: absolute;
  left: 11%;
  top: 80%;
  padding: 17px 40px;
  border-radius: 0px;
  border: 0;
  background-color: #ff489e;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  transition: all 0.5s ease;
  z-index: 1;
  color: black; /* Couleur du texte de base */
}

.started:hover {
  letter-spacing: 3px;
  background-color: white;
  color: black; /* Couleur du texte sur le hover */
  box-shadow: #6f6ff0 0px 7px 29px 0px;
}

.started:active {
  letter-spacing: 3px;
  background-color: hsl(261deg 80% 48%);
  color: #6f6ff0; /* Couleur du texte sur le active */
  box-shadow: rgb(93 24 220) 0px 0px 0px 0px;
  transform: translateY(10px);
  transition: 100ms;
}

.co {
  position: absolute;
  right: 10%;
  top: 4%;
  padding: 17px 40px;
  border-radius: 40px;
  border: 0;
  background-color: #ff489e;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  transition: all 0.5s ease;
  z-index: 1;
  color: black; /* Couleur du texte de base */
}

.co:hover {
  letter-spacing: 3px;
  background-color: white;
  color: black; /* Couleur du texte sur le hover */
  box-shadow: #6f6ff0 0px 7px 29px 0px;
}

.co:active {
  letter-spacing: 3px;
  background-color: #6fa7f0;
  color: #6f6ff0; /* Couleur du texte sur le active */
  box-shadow: rgb(11, 98, 228) 0px 7px 29px 0px;
  transform: translateY(10px);
  transition: 100ms;
}

.home-page-container {
  height: 100vh; /* Set the height to 100% of the viewport height */
  overflow: auto; /* Enable scrolling when content exceeds the container height */
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

/* homepage.css (ou tout autre fichier CSS où vous définissez les styles) */

.content-container {
  /* Styles pour le contenu existant (Logo, vidéo, boutons, etc.) */
  position: relative;
  height: 100vh; /* Hauteur équivalente à la hauteur de l'écran */
  overflow-y: scroll; /* Permet le défilement vertical si le contenu dépasse la hauteur de l'écran */
}

.image-container {
  /* Styles pour l'image */
  position: relative;
  padding-top: 20px; /* Espacement pour laisser de l'espace entre le contenu existant et l'image */
}

.image-container img {
  /* Styles pour l'image elle-même */
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.addbutton {
  background-color: #6f6ff0; 
  color: #fff;
  padding: 10px 20px;
  width: 200px;
  height: 50px;
  border: none;
  border-radius: 0px;
  cursor: pointer;
  transition: background-color 0.3s;
  position: absolute;
}

@media only screen and (min-device-width: 1030px) and (min-device-height: 600px) and (max-device-width: 1400px) {
  .co {
    font-size: 35px;
  }
  .started {
    top: 80%;
    font-size: 50px;
  }
}

@media only screen and (min-device-width: 1400px) and (min-device-height: 600px) {
  .co {
    font-size: 20px;
  }
  .started {
    top: 80%;
    font-size: 48px;
  }
}

@media only screen and (min-width: 360px) and (max-height: 900px) and (max-width: 1025px) {
  .co {
    font-size: 10px;
  }
  .started {
    top: 75%;
    font-size: 25px;
  }
}
