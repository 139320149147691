/* royalties.css */

.royalties-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.royalties-container h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.royalties-container p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.royalties-table {
  width: 100%;
  border-collapse: collapse;
}

.royalties-table th, .royalties-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.royalties-table th {
  background-color: #f2f2f2;
  cursor: pointer;
}

.royalties-table th:hover {
  background-color: #ddd;
}

.royalties-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.royalties-table tbody tr:hover {
  background-color: #f1f1f1;
}

.royalties-table .song-title {
  font-weight: bold;
  color: #7D4EDA;
}

.royalties-table .pdate {
  font-weight: bold;
  color: #7D4EDA;
}

.sort-icon::after {
  content: ' \25B2'; /* Default to an up arrow */
  font-size: 0.8em;
}

th.sort-asc .sort-icon::after {
  content: ' \25B2'; /* Up arrow */
}

th.sort-desc .sort-icon::after {
  content: ' \25BC'; /* Down arrow */
}